<template>
  <footer>
    <div class="maxw--body footer__inner">
      <div class="footer__col">
        <h5>Informatie</h5>
        <nuxt-link to="/contact">Contact</nuxt-link>
        <a :href="`mailto:${data?.contact_email}`">{{ data?.contact_email }}</a>
        <a :href="data?.maps_link">{{ data?.address }}</a>
        <nuxt-link to="/privacy-beleid">Privacybeleid</nuxt-link>
      </div>
      <div class="footer__col">
        <h5>Social Media</h5>
        <a :href="data?.facebook_link" class="social">
          <IconsFacebook class="social__icon"/>
          <span class="social__label">{{ data?.facebook_label }}</span>
        </a>
        <a :href="data?.instagram_link" class="social">
          <IconsInstagram class="social__icon"/>
          <span class="social__label">{{ data?.instagram_label }}</span>
        </a>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import GeneralSettings from '~/typings/components/general_settings';

const { findOne } = useStrapi();

const { data } = await useAsyncData(
  'generalsettings',
  () => findOne<GeneralSettings>('general-setting'),
  {
    transform: flattenStrapiObject
  }
);
</script>

<style lang="scss">
footer {
  width: 100%;
  background: $dark_background;
  color: $background;
  padding: 2rem;
  min-height: 10rem;

  h5 {
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  a {
    color: $background;
    display: block;
    font-weight: 400;
    text-decoration: none;
    margin: 0.3rem 0;
    transition: all 215ms ease-in-out;

    &:hover, &:active, &:focus {
      color: rgb(193, 193, 193);
    }
  }
}

.footer__inner {
  display: flex;
  justify-content: space-between;

  @include breakpoint(null, $sm) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
  }
}

.footer__col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 252px;
}

.social {
  display: flex;
  align-items: center;

  &:hover, &:active, &:focus {
      color: rgb(193, 193, 193);
    }
}

.social__label {
  margin-left: 0.5rem;
  font-weight: 500;
  font-size: 0.9rem;
  color: currentColor;
}
</style>
