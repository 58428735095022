import revive_payload_client_4sVQNw7RlN from "/home/runner/work/virgo/virgo/website/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/virgo/virgo/website/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/runner/work/virgo/virgo/website/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/virgo/virgo/website/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/virgo/virgo/website/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/home/runner/work/virgo/virgo/website/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_client_LcKgStRyi6 from "/home/runner/work/virgo/virgo/website/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_client_pSWXSWfTHG from "/home/runner/work/virgo/virgo/website/node_modules/nuxt-mapbox/dist/runtime/plugin.client.mjs";
import plugin_OrkQhMqHci from "/home/runner/work/virgo/virgo/website/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import script_YrMPz5ICtR from "/home/runner/work/virgo/virgo/website/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import strapi_plugin_35gTOGMBRa from "/home/runner/work/virgo/virgo/website/node_modules/@nuxtjs/strapi/dist/runtime/strapi.plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/virgo/virgo/website/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auto_animate_XcJE6OGLWh from "/home/runner/work/virgo/virgo/website/plugins/auto_animate.ts";
import markdown_nljPFoZ1ix from "/home/runner/work/virgo/virgo/website/plugins/markdown.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_client_LcKgStRyi6,
  plugin_client_pSWXSWfTHG,
  plugin_OrkQhMqHci,
  script_YrMPz5ICtR,
  strapi_plugin_35gTOGMBRa,
  chunk_reload_client_UciE0i6zes,
  auto_animate_XcJE6OGLWh,
  markdown_nljPFoZ1ix
]