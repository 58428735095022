import { Strapi4ResponseMany, Strapi4ResponseSingle } from "@nuxtjs/strapi/dist/runtime/types";
import Image from "typings/items/image";

export function flattenStrapiObject<T>(object: Strapi4ResponseSingle<T> | undefined | null) {
  return {
    ...object?.data?.attributes,
    id: object?.data?.id
  } as T;
}

export function flattenStrapiCollection<T>(collection: Strapi4ResponseMany<T> | undefined | null) {
  if (!collection?.data) {
    return [];
  }

  return collection.data.map<T>((item) => ({
    ...item.attributes,
    id: item.id
  })) as Array<T>;
}

export function getStrapiImageLocation(image: Strapi4ResponseSingle<Image> | undefined | null, prefixed: boolean = false, returnAsString: boolean = true) {
  if (!image || !image.data) {
    return returnAsString ? 'undefined' : undefined;
  }

  const imageFlattened = flattenStrapiObject(image);
  return getFlattenedStrapiImageLocation(imageFlattened, prefixed);
}

export function getFlattenedStrapiImageLocation(image: Image | undefined | null, prefixed: boolean = false, returnAsString: boolean = true) {
  if (!image) {
    return returnAsString ? 'undefined' : undefined;
  }

  let value = `${image.hash || (returnAsString ? 'undefined' : undefined)}${image.ext}`;

  if (prefixed) {
    const runtimeConfig = useRuntimeConfig();
    value = `${runtimeConfig.public.strapi.url}/uploads/${value}`;
  }

  return value;
}
