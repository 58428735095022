<template>
  <div class="nav__item" @mouseenter="openMenu" @mouseleave="closeMenu">
    <nuxt-link 
      :to="item.url" 
      :target="item.target || '_self'" 
      class="nav__link"
      :class="{ 'nav__item--menu': hasChildren, 'active': menuIsOpen }"
    >
      {{ item.title }}

      <svg xmlns="http://www.w3.org/2000/svg" v-if="hasChildren && !isMobile" class="nav__chevron" viewBox="0 0 24 24">
        <path fill="currentColor" d="M8.12 9.29L12 13.17l3.88-3.88a.996.996 0 1 1 1.41 1.41l-4.59 4.59a.996.996 0 0 1-1.41 0L6.7 10.7a.996.996 0 0 1 0-1.41c.39-.38 1.03-.39 1.42 0z"/>
      </svg>
    </nuxt-link>
    <transition name="nav-menu">
      <ul class="nav__dropdown block--small flat" v-show="(menuIsOpen || isMobile)  && hasChildren">
        <li v-for="child in children" :key="child.id">
          <nuxt-link :to="item.url + child.url" class="nav__dropdown_link" @click="emit('linked')">
            {{ child.title }}
          </nuxt-link>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "nuxt/dist/app/compat/capi";

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  isMobile: {
    type: Boolean as PropType<boolean>,
    default: false
  }
});

const emit = defineEmits(['linked']);

const children = computed(() => flattenStrapiCollection(props.item.children) || []);
const hasChildren = computed(() => children.value.length);

const menuIsOpen = ref<boolean>(false);

const closeMenu = () => menuIsOpen.value = false;
const openMenu = () => menuIsOpen.value = true;
</script>

<style lang="scss">
.nav__item {
  position: relative;

  .navigation--mobile & {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.nav__item--menu {
  margin-top: 0.75rem;
  padding-bottom: 0.75rem;

  .navigation--mobile & {
    padding-bottom: 0.25rem;
    margin-top: 0;
  }
}

.nav__dropdown {
  position: absolute;
  top: calc(100% + 0.75rem);
  left: -.75rem;
  padding: 0.75rem;
  background: $background;
  border-radius: 0.75rem;
  display: inline-flex;
  flex-direction: column;
  width: auto;
  row-gap: 0.75rem;
  min-width: calc(100% + 1.5rem);

  .navigation--mobile & {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    background: transparent;
  }
}

.nav__link {
  color: $background;
  text-decoration: none;
  font-weight: bold;
  transition: all 85ms ease-in-out;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 2rem;
    border-radius: 1rem;
    width: 0;
    background: $background;
    transition: all 105ms ease-in-out;
    z-index: -1;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &:hover,
  &:active,
  &.active {
    color: $primary;
    transition: all 165ms ease-in-out;;

    &::before {
      width: calc(100% + 1.5rem);
      left: -0.8rem;
      transform: translate(0, 0);
      transition: all 350ms cubic-bezier(.23,1,.32,1);
    }
  }

  .navigation--mobile & {
    color: black;
    font-size: 1.2rem;
    left: 0;
  }
}

.nav__chevron {
  color: inherit;
  height: 28px;
  width: 28px;
  display: inline-block;
  margin-left: 0.25rem;
}

.nav__dropdown_link {
  white-space: nowrap;
  color: $text;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 215ms ease-in-out;

  &:hover,
  &:active {
    color: $primary;
  }
}

.navigation--desktop {
  // Only do this on desktop (the transition stuff)
  .nav-menu-enter-active,
  .nav-menu-leave-active {
    transition: opacity 215ms ease-in-out, transform 325ms cubic-bezier(.17, .67, .26, .98);
  }

  .nav-menu-leave-active {
    transition: opacity 165ms ease-in-out, transform 500ms cubic-bezier(.17, .67, .26, .98);
  }

  .nav-menu-enter-from,
  .nav-menu-leave-to {
    transform: translateY(-10px);
    opacity: 0;
  }
}
</style>
