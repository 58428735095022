<template>
  <ClientOnly>
    <HeadlessDialog :open="open" class="modal" @close="() => closeDialog(data?.id)" v-if="!pending && data">
      <div class="modal__backdrop"></div>
      <HeadlessDialogPanel class="card maxw--neck model__panel text--center">
        <button class="modal__close" @click="() => closeDialog(data?.id)">
          <IconsClose/>
        </button>
        <HeadlessDialogTitle class="mb-2">{{ data?.title }}</HeadlessDialogTitle>
        <HeadlessDialogDescription as="div">
          <nuxt-img v-if="data?.image?.data" provider="strapi" :src="getStrapiImageLocation(data.image)" class="fit"/>
          <div v-if="data?.text" v-html="$md.render(data?.text || '')" class="rendered"></div>
          <a v-if="data?.link" :href="data?.link" class="btn mt-2">Bekijken</a>
        </HeadlessDialogDescription>
      </HeadlessDialogPanel>
    </HeadlessDialog>
  </ClientOnly>
</template>

<script lang="ts" setup>
import Popup from '~/typings/components/popup';

const open = ref<boolean>(false);

const confirmedDialogs = useLocalStorage<Array<number>>('virgo-popup', []);

const openDialog = () => open.value = true;
const closeDialog = (id: number|null|undefined) => {
  open.value = false;

  if (id) {
    confirmedDialogs.value.push(id);
  }
};

const { find } = useStrapi();

const { pending, data } = useAsyncData(
  'popupdata',
  () => find<Popup>('popups', {
    filters: {
      id: {
        $notIn: confirmedDialogs.value
      }
    },
    populate: ['image'],
    sort: 'from:asc',
    pagination: {
      pageSize: 1,
      page: 1
    }
  }),
  {
    transform: (data) => flattenStrapiCollection(data)?.[0] || null,
    lazy: true,
    server: false
  }
)

watch(data, (data) => open.value = Boolean(data));
</script>
