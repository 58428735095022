<template>
  <div class="body">
    <Header/>
    <Modal/>
    <NuxtPage/>
    <Footer/>
  </div>
</template>

<script lang="ts">
import { setDefaultOptions } from 'date-fns';
import { nl } from 'date-fns/locale/index.js';

setDefaultOptions({ locale: nl });

export default {}
</script>
