import { default as aanpassenE0y94BdJ0HMeta } from "/home/runner/work/virgo/virgo/website/pages/account/aanpassen.vue?macro=true";
import { default as indexsnBbdwtzh1Meta } from "/home/runner/work/virgo/virgo/website/pages/account/index.vue?macro=true";
import { default as wachtwoord_45wijzigenCvmL1I2jXWMeta } from "/home/runner/work/virgo/virgo/website/pages/account/wachtwoord-wijzigen.vue?macro=true";
import { default as _91slug_93N3tIZkUYXsMeta } from "/home/runner/work/virgo/virgo/website/pages/albums/[slug].vue?macro=true";
import { default as index9FVNj3BojJMeta } from "/home/runner/work/virgo/virgo/website/pages/albums/index.vue?macro=true";
import { default as contactHeDBKHOPQrMeta } from "/home/runner/work/virgo/virgo/website/pages/contact.vue?macro=true";
import { default as _91slug_93Jt0pZi16kYMeta } from "/home/runner/work/virgo/virgo/website/pages/evenementen/[slug].vue?macro=true";
import { default as indexBypetB6udaMeta } from "/home/runner/work/virgo/virgo/website/pages/evenementen/index.vue?macro=true";
import { default as _91uuid_93uzDbVIILROMeta } from "/home/runner/work/virgo/virgo/website/pages/evenementen/rsvp/[uuid].vue?macro=true";
import { default as faqERVjIBD8iDMeta } from "/home/runner/work/virgo/virgo/website/pages/faq.vue?macro=true";
import { default as indextYP6bdjPrYMeta } from "/home/runner/work/virgo/virgo/website/pages/index.vue?macro=true";
import { default as inschrijvenEvUYGNPnTqMeta } from "/home/runner/work/virgo/virgo/website/pages/inschrijven.vue?macro=true";
import { default as introductie_45periode8AVsKPFRQcMeta } from "/home/runner/work/virgo/virgo/website/pages/introductie-periode.vue?macro=true";
import { default as loginThBudxAk59Meta } from "/home/runner/work/virgo/virgo/website/pages/login.vue?macro=true";
import { default as _91slug_93XMwPtnX8mwMeta } from "/home/runner/work/virgo/virgo/website/pages/over/bestuur/[slug].vue?macro=true";
import { default as index4u2t7EWorBMeta } from "/home/runner/work/virgo/virgo/website/pages/over/bestuur/index.vue?macro=true";
import { default as _91slug_93HxvRp0m2SVMeta } from "/home/runner/work/virgo/virgo/website/pages/over/clubs-commissies/[slug].vue?macro=true";
import { default as index4OTPVNHkhzMeta } from "/home/runner/work/virgo/virgo/website/pages/over/clubs-commissies/index.vue?macro=true";
import { default as de_45sooslkbzJMSnUqMeta } from "/home/runner/work/virgo/virgo/website/pages/over/de-soos.vue?macro=true";
import { default as geschiedeniskT4U8AJsQtMeta } from "/home/runner/work/virgo/virgo/website/pages/over/geschiedenis.vue?macro=true";
import { default as indexcp6MO4aIPuMeta } from "/home/runner/work/virgo/virgo/website/pages/over/index.vue?macro=true";
import { default as privacy_45beleidkKNFDhKHOPMeta } from "/home/runner/work/virgo/virgo/website/pages/privacy-beleid.vue?macro=true";
import { default as registrerenk0tU88eEmWMeta } from "/home/runner/work/virgo/virgo/website/pages/registreren.vue?macro=true";
import { default as indexQpRKfE9LoTMeta } from "/home/runner/work/virgo/virgo/website/pages/sponsoren/index.vue?macro=true";
import { default as kortingenYpAeVSXOo1Meta } from "/home/runner/work/virgo/virgo/website/pages/sponsoren/kortingen.vue?macro=true";
import { default as wachtwoord_45resetten48cbghJdZIMeta } from "/home/runner/work/virgo/virgo/website/pages/wachtwoord-resetten.vue?macro=true";
import { default as wachtwoord_45vergetengkg62hImTUMeta } from "/home/runner/work/virgo/virgo/website/pages/wachtwoord-vergeten.vue?macro=true";
export default [
  {
    name: aanpassenE0y94BdJ0HMeta?.name ?? "account-aanpassen",
    path: aanpassenE0y94BdJ0HMeta?.path ?? "/account/aanpassen",
    meta: aanpassenE0y94BdJ0HMeta || {},
    alias: aanpassenE0y94BdJ0HMeta?.alias || [],
    redirect: aanpassenE0y94BdJ0HMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/account/aanpassen.vue").then(m => m.default || m)
  },
  {
    name: indexsnBbdwtzh1Meta?.name ?? "account",
    path: indexsnBbdwtzh1Meta?.path ?? "/account",
    meta: indexsnBbdwtzh1Meta || {},
    alias: indexsnBbdwtzh1Meta?.alias || [],
    redirect: indexsnBbdwtzh1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45wijzigenCvmL1I2jXWMeta?.name ?? "account-wachtwoord-wijzigen",
    path: wachtwoord_45wijzigenCvmL1I2jXWMeta?.path ?? "/account/wachtwoord-wijzigen",
    meta: wachtwoord_45wijzigenCvmL1I2jXWMeta || {},
    alias: wachtwoord_45wijzigenCvmL1I2jXWMeta?.alias || [],
    redirect: wachtwoord_45wijzigenCvmL1I2jXWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/account/wachtwoord-wijzigen.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93N3tIZkUYXsMeta?.name ?? "albums-slug",
    path: _91slug_93N3tIZkUYXsMeta?.path ?? "/albums/:slug()",
    meta: _91slug_93N3tIZkUYXsMeta || {},
    alias: _91slug_93N3tIZkUYXsMeta?.alias || [],
    redirect: _91slug_93N3tIZkUYXsMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/albums/[slug].vue").then(m => m.default || m)
  },
  {
    name: index9FVNj3BojJMeta?.name ?? "albums",
    path: index9FVNj3BojJMeta?.path ?? "/albums",
    meta: index9FVNj3BojJMeta || {},
    alias: index9FVNj3BojJMeta?.alias || [],
    redirect: index9FVNj3BojJMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/albums/index.vue").then(m => m.default || m)
  },
  {
    name: contactHeDBKHOPQrMeta?.name ?? "contact",
    path: contactHeDBKHOPQrMeta?.path ?? "/contact",
    meta: contactHeDBKHOPQrMeta || {},
    alias: contactHeDBKHOPQrMeta?.alias || [],
    redirect: contactHeDBKHOPQrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Jt0pZi16kYMeta?.name ?? "evenementen-slug",
    path: _91slug_93Jt0pZi16kYMeta?.path ?? "/evenementen/:slug()",
    meta: _91slug_93Jt0pZi16kYMeta || {},
    alias: _91slug_93Jt0pZi16kYMeta?.alias || [],
    redirect: _91slug_93Jt0pZi16kYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/evenementen/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexBypetB6udaMeta?.name ?? "evenementen",
    path: indexBypetB6udaMeta?.path ?? "/evenementen",
    meta: indexBypetB6udaMeta || {},
    alias: indexBypetB6udaMeta?.alias || [],
    redirect: indexBypetB6udaMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/evenementen/index.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93uzDbVIILROMeta?.name ?? "evenementen-rsvp-uuid",
    path: _91uuid_93uzDbVIILROMeta?.path ?? "/evenementen/rsvp/:uuid()",
    meta: _91uuid_93uzDbVIILROMeta || {},
    alias: _91uuid_93uzDbVIILROMeta?.alias || [],
    redirect: _91uuid_93uzDbVIILROMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/evenementen/rsvp/[uuid].vue").then(m => m.default || m)
  },
  {
    name: faqERVjIBD8iDMeta?.name ?? "faq",
    path: faqERVjIBD8iDMeta?.path ?? "/faq",
    meta: faqERVjIBD8iDMeta || {},
    alias: faqERVjIBD8iDMeta?.alias || [],
    redirect: faqERVjIBD8iDMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indextYP6bdjPrYMeta?.name ?? "index",
    path: indextYP6bdjPrYMeta?.path ?? "/",
    meta: indextYP6bdjPrYMeta || {},
    alias: indextYP6bdjPrYMeta?.alias || [],
    redirect: indextYP6bdjPrYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inschrijvenEvUYGNPnTqMeta?.name ?? "inschrijven",
    path: inschrijvenEvUYGNPnTqMeta?.path ?? "/inschrijven",
    meta: inschrijvenEvUYGNPnTqMeta || {},
    alias: inschrijvenEvUYGNPnTqMeta?.alias || [],
    redirect: inschrijvenEvUYGNPnTqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/inschrijven.vue").then(m => m.default || m)
  },
  {
    name: introductie_45periode8AVsKPFRQcMeta?.name ?? "introductie-periode",
    path: introductie_45periode8AVsKPFRQcMeta?.path ?? "/introductie-periode",
    meta: introductie_45periode8AVsKPFRQcMeta || {},
    alias: introductie_45periode8AVsKPFRQcMeta?.alias || [],
    redirect: introductie_45periode8AVsKPFRQcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/introductie-periode.vue").then(m => m.default || m)
  },
  {
    name: loginThBudxAk59Meta?.name ?? "login",
    path: loginThBudxAk59Meta?.path ?? "/login",
    meta: loginThBudxAk59Meta || {},
    alias: loginThBudxAk59Meta?.alias || [],
    redirect: loginThBudxAk59Meta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93XMwPtnX8mwMeta?.name ?? "over-bestuur-slug",
    path: _91slug_93XMwPtnX8mwMeta?.path ?? "/over/bestuur/:slug()",
    meta: _91slug_93XMwPtnX8mwMeta || {},
    alias: _91slug_93XMwPtnX8mwMeta?.alias || [],
    redirect: _91slug_93XMwPtnX8mwMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/over/bestuur/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4u2t7EWorBMeta?.name ?? "over-bestuur",
    path: index4u2t7EWorBMeta?.path ?? "/over/bestuur",
    meta: index4u2t7EWorBMeta || {},
    alias: index4u2t7EWorBMeta?.alias || [],
    redirect: index4u2t7EWorBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/over/bestuur/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HxvRp0m2SVMeta?.name ?? "over-clubs-commissies-slug",
    path: _91slug_93HxvRp0m2SVMeta?.path ?? "/over/clubs-commissies/:slug()",
    meta: _91slug_93HxvRp0m2SVMeta || {},
    alias: _91slug_93HxvRp0m2SVMeta?.alias || [],
    redirect: _91slug_93HxvRp0m2SVMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/over/clubs-commissies/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4OTPVNHkhzMeta?.name ?? "over-clubs-commissies",
    path: index4OTPVNHkhzMeta?.path ?? "/over/clubs-commissies",
    meta: index4OTPVNHkhzMeta || {},
    alias: index4OTPVNHkhzMeta?.alias || [],
    redirect: index4OTPVNHkhzMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/over/clubs-commissies/index.vue").then(m => m.default || m)
  },
  {
    name: de_45sooslkbzJMSnUqMeta?.name ?? "over-de-soos",
    path: de_45sooslkbzJMSnUqMeta?.path ?? "/over/de-soos",
    meta: de_45sooslkbzJMSnUqMeta || {},
    alias: de_45sooslkbzJMSnUqMeta?.alias || [],
    redirect: de_45sooslkbzJMSnUqMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/over/de-soos.vue").then(m => m.default || m)
  },
  {
    name: geschiedeniskT4U8AJsQtMeta?.name ?? "over-geschiedenis",
    path: geschiedeniskT4U8AJsQtMeta?.path ?? "/over/geschiedenis",
    meta: geschiedeniskT4U8AJsQtMeta || {},
    alias: geschiedeniskT4U8AJsQtMeta?.alias || [],
    redirect: geschiedeniskT4U8AJsQtMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/over/geschiedenis.vue").then(m => m.default || m)
  },
  {
    name: indexcp6MO4aIPuMeta?.name ?? "over",
    path: indexcp6MO4aIPuMeta?.path ?? "/over",
    meta: indexcp6MO4aIPuMeta || {},
    alias: indexcp6MO4aIPuMeta?.alias || [],
    redirect: indexcp6MO4aIPuMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/over/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45beleidkKNFDhKHOPMeta?.name ?? "privacy-beleid",
    path: privacy_45beleidkKNFDhKHOPMeta?.path ?? "/privacy-beleid",
    meta: privacy_45beleidkKNFDhKHOPMeta || {},
    alias: privacy_45beleidkKNFDhKHOPMeta?.alias || [],
    redirect: privacy_45beleidkKNFDhKHOPMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/privacy-beleid.vue").then(m => m.default || m)
  },
  {
    name: registrerenk0tU88eEmWMeta?.name ?? "registreren",
    path: registrerenk0tU88eEmWMeta?.path ?? "/registreren",
    meta: registrerenk0tU88eEmWMeta || {},
    alias: registrerenk0tU88eEmWMeta?.alias || [],
    redirect: registrerenk0tU88eEmWMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/registreren.vue").then(m => m.default || m)
  },
  {
    name: indexQpRKfE9LoTMeta?.name ?? "sponsoren",
    path: indexQpRKfE9LoTMeta?.path ?? "/sponsoren",
    meta: indexQpRKfE9LoTMeta || {},
    alias: indexQpRKfE9LoTMeta?.alias || [],
    redirect: indexQpRKfE9LoTMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/sponsoren/index.vue").then(m => m.default || m)
  },
  {
    name: kortingenYpAeVSXOo1Meta?.name ?? "sponsoren-kortingen",
    path: kortingenYpAeVSXOo1Meta?.path ?? "/sponsoren/kortingen",
    meta: kortingenYpAeVSXOo1Meta || {},
    alias: kortingenYpAeVSXOo1Meta?.alias || [],
    redirect: kortingenYpAeVSXOo1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/sponsoren/kortingen.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45resetten48cbghJdZIMeta?.name ?? "wachtwoord-resetten",
    path: wachtwoord_45resetten48cbghJdZIMeta?.path ?? "/wachtwoord-resetten",
    meta: wachtwoord_45resetten48cbghJdZIMeta || {},
    alias: wachtwoord_45resetten48cbghJdZIMeta?.alias || [],
    redirect: wachtwoord_45resetten48cbghJdZIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/wachtwoord-resetten.vue").then(m => m.default || m)
  },
  {
    name: wachtwoord_45vergetengkg62hImTUMeta?.name ?? "wachtwoord-vergeten",
    path: wachtwoord_45vergetengkg62hImTUMeta?.path ?? "/wachtwoord-vergeten",
    meta: wachtwoord_45vergetengkg62hImTUMeta || {},
    alias: wachtwoord_45vergetengkg62hImTUMeta?.alias || [],
    redirect: wachtwoord_45vergetengkg62hImTUMeta?.redirect || undefined,
    component: () => import("/home/runner/work/virgo/virgo/website/pages/wachtwoord-vergeten.vue").then(m => m.default || m)
  }
]