
import { defuFn } from '/home/runner/work/virgo/virgo/website/node_modules/defu/dist/defu.mjs'

const inlineConfig = {
  "_MAPBOX_CONFIG": {
    "accessToken": ""
  }
}



export default /* #__PURE__ */ defuFn(inlineConfig)
