<template>
  <nav class="navigation">
    <div class="navigation--desktop">
      <NavigationLink v-for="item in items" :item="item" :key="item.id"/>
      <a @click="doLogout" class="nav__link" v-if="isAuthenticated">Uitloggen</a>
      <nuxt-link to="/login" class="nav__link" v-else>Inloggen</nuxt-link>
      <nuxt-link to="/introductie-periode" class="btn btn--accent" v-if="showIntroductionButton">Introductie periode</nuxt-link>
      <nuxt-link to="/inschrijven" class="btn">Inschrijven</nuxt-link>
    </div>
    <div class="navigation--mobile">
      <div class="navigation__header">
        <NavigationHamburger v-model="mobileOpen"/>
      </div>
      <div class="navigation__content" :class="{open: mobileOpen}">
        <NavigationLink v-for="item in items" :item="item" :key="item.id" is-mobile @click="mobileOpen = false"/>
        <button @click="doLogout" class="nav__link" v-if="isAuthenticated">Uitloggen</button>
        <nuxt-link to="/login" class="nav__link" v-else @click="mobileOpen = false">Inloggen</nuxt-link>
        <nuxt-link to="/introductie-periode" class="btn btn--accent mt-1" @click="mobileOpen = false" v-if="showIntroductionButton">Introductie periode</nuxt-link>
        <nuxt-link to="/inschrijven" class="btn mt-1" @click="mobileOpen = false">Inschrijven</nuxt-link>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { PropType } from "nuxt/dist/app/compat/capi";
import IntroductionPage from "~/typings/pages/introduction_page";

// TODO: update to link object & create interface
defineProps({
  items: {
    type: Array as PropType<Array<object>>,
    default: []
  }
});

const mobileOpen = ref<boolean>(false);

const { logout } = useStrapiAuth();

const user = useStrapiUser();
const isAuthenticated = computed<boolean>(() => Boolean(user.value));

const doLogout = async () => {
  logout();
  mobileOpen.value = false;
  return await navigateTo('/login');
}

const { findOne } = useStrapi();

const { data: introductionPage } = await useAsyncData(
  () => findOne<IntroductionPage>('introduction-week-page'),
  {
    transform: flattenStrapiObject
  }
);

const showIntroductionButton = computed(() => introductionPage.value?.showInNavigation);
</script>

<style lang="scss">
.navigation--desktop {
  display: flex;
  align-items: center;
  column-gap: 2rem;

  @include breakpoint(null, $xxl) {
    display: none;
  }
}

.navigation--mobile {
  position: relative;

  @include breakpoint($xxl) {
    display: none;
  }

  .navigation__content {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    height: 100vh;
    width: 100vw;
    display: none;
    background: $background;
    padding-top: 5rem;
    padding-bottom: 1rem;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1.5rem;
    overflow: auto;

    &.open {
      display: flex;
    }
  }

  .navigation__header {
    position: fixed;
    top: 2rem;
    right: 1rem;
    z-index: 8;
  }
}
</style>
