<template>
  <header class="header">
    <nuxt-link to="/">
      <!-- <Logo class="logo"/> -->
      <nuxt-img class="logo" src="/logo.png"></nuxt-img>
    </nuxt-link>

    <Navigation :items="items"/>
  </header>
</template>

<script lang="ts" setup>
import Logo from '~/assets/svg/logo.svg?component';

const { findOne } = useStrapi();

const { data } = await useAsyncData(
  'header_menu',
  () => findOne('menus', 1, { populate: '*', nested: true }),
  {
    transform: flattenStrapiObject
  }
);

const items = computed(() => flattenStrapiCollection<object>(data.value?.items || []));
</script>

<style lang="scss">
.header {
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;

  &::after {
    content: "";
    background-image: linear-gradient(rgba(0,0,0,.75), transparent);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: -1;
  }

  .logo {
    display: block;
    height: 80px;
  }
}
</style>
